import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Link, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				О нас | DriveWell - Ваш путь к уверенности!
			</title>
			<meta name={"description"} content={"В компании DriveWell мы стремимся к тому, чтобы создавать уверенных и способных водителей. Наша история проста: мы стремимся предоставить высококачественное обучение вождению без лишней суеты."} />
			<meta property={"og:title"} content={"О нас | DriveWell - Ваш путь к уверенности!"} />
			<meta property={"og:description"} content={"В компании DriveWell мы стремимся к тому, чтобы создавать уверенных и способных водителей. Наша история проста: мы стремимся предоставить высококачественное обучение вождению без лишней суеты."} />
			<meta property={"og:image"} content={"https://oionslace.com/images/bg.jpg"} />
			<link rel={"shortcut icon"} href={"https://oionslace.com/images/i.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://oionslace.com/images/i.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://oionslace.com/images/i.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://oionslace.com/images/i.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://oionslace.com/images/i.png"} />
			<meta name={"msapplication-TileImage"} content={"https://oionslace.com/images/i.png"} />
			<meta name={"msapplication-TileColor"} content={"https://oionslace.com/images/i.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 180px 0" lg-padding="90px 0 90px 0" sm-padding="45px 0 60px 0" quarkly-title="About-15">
			<Override slot="SectionContent" flex-direction="row" lg-flex-wrap="wrap" sm-min-width="280px" />
			<Box
				width="20%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				justify-content="space-between"
				lg-width="100%"
				lg-align-items="stretch"
				lg-flex-direction="row"
				lg-justify-content="flex-start"
				md-flex-direction="column"
			>
				<Box
					lg-width="80%"
					lg-display="flex"
					lg-flex-direction="column"
					lg-align-items="flex-start"
					lg-justify-content="flex-end"
					md-width="100%"
					height="100%"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="space-around"
				>
					<Text
						margin="0px 0px 12px 0px"
						font="--base"
						text-transform="uppercase"
						color="--primary"
						letter-spacing="1px"
					>
						О Нас
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						font="--headline2"
						color="--dark"
						lg-text-align="left"
						md-padding="0px 0px 0px 0px"
					>
						Расширьте возможности своего путешествия с DriveWell
					</Text>
				</Box>
			</Box>
			<Box
				width="80%"
				display="flex"
				justify-content="flex-end"
				lg-width="100%"
				lg-margin="48px 0px 0px 0px"
				lg-flex-direction="column"
			>
				<Box position="relative">
					<Image
						src="https://oionslace.com/images/2.jpg"
						display="block"
						object-fit="cover"
						position="absolute"
						height="250px"
						width="300px"
						left="-120px"
						bottom="-120px"
						lg-position="static"
						lg-width="100%"
						lg-height="auto"
					/>
					<Image
						src="https://oionslace.com/images/3.jpg"
						display="block"
						object-fit="cover"
						height="380px"
						width="550px"
						lg-width="100%"
						lg-height="auto"
						lg-margin="8px 0px 0px 0px"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					О компании DriveWell
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						В компании DriveWell мы стремимся к тому, чтобы создавать уверенных и способных водителей. Наша история проста: мы стремимся предоставить высококачественное обучение вождению без лишней суеты. Мы не полагаемся на броские приемы или экстравагантные рассказы об успехах. Вместо этого мы концентрируемся на главном - помочь вам стать более безопасным и уверенным водителем.
					</Text>
					<Box min-width="10px" min-height="10px" display="flex" sm-flex-direction="column">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							margin="0px 25px 0px 0px"
							sm-margin="0px 0 10px 0px"
						>
							<Text margin="0px 15px 0px 0px" font="--lead" color="#727a82" white-space="nowrap">
								Телефон
							</Text>
							<Link href="tel:+7 778 808 1353" color="#000000" text-decoration-line="initial" font="--lead">
								+7 778 808 1353
							</Link>
						</Box>
						<Box min-width="10px" min-height="10px" display="flex" margin="0px 0 0px 0px">
							<Text margin="0px 15px 0px 0px" font="--lead" color="#727a82" white-space="nowrap">
								E-mail
							</Text>
							<Link href="mailto:contact@oionslace.com" color="#000000" text-decoration-line="initial" font="--lead">
								contact@oionslace.com
							</Link>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-6">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 84px"
				margin="0px 0px 40px 0px"
				lg-grid-template-columns="1fr"
				sm-grid-gap="36px 24px"
			>
				<Box min-width="100px" min-height="100px" lg-padding="0px 150px 0px 0px" md-padding="0px 0 0px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 700 42px/1.2 --fontFamily-sans">
						Наша миссия
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 300 22px/1.5 --fontFamily-sansHelvetica">
						DriveWell - это не просто автошкола, это место, где вы найдете поддержку и опыт, необходимые для того, чтобы стать лучшим водителем. Наша миссия заключается в предоставлении высококачественного индивидуального обучения вождению, которое позволяет людям уверенно ориентироваться на дороге. Благодаря опытным инструкторам, индивидуальным учебным программам и конкурентоспособным ценам DriveWell является лучшим выбором для водителей любого уровня.
					</Text>
					<Button
						background="#5448fd"
						padding="12px 28px 12px 28px"
						border-radius="4px"
						font="normal 400 18px/1.5 --fontFamily-sans"
						margin="0px 0px 60px 0px"
						sm-margin="0px 0px 40px 0px"
						href="/contact-us"
						type="link"
						text-decoration-line="initial"
					>
						Выберите DriveWell
					</Button>
				</Box>
				<Image
					src="https://oionslace.com/images/4.jpg"
					display="block"
					width="100%"
					height="100%"
					object-fit="cover"
					border-radius="25px"
					margin="0px 0px 0px 0"
					lg-order="-1"
					lg-height="500px"
					md-height="400px"
				/>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0px 0px -160px"
				sm-flex-direction="column"
				sm-margin="0px 0px 0px 0"
			>
				<Image
					src="https://oionslace.com/images/1.jpg"
					display="block"
					width="100%"
					border-radius="25px"
					margin="80px 0px 0px 0px"
					object-fit="cover"
					sm-margin="0 0px 0px 0px"
				/>
				<Image
					src="https://oionslace.com/images/5.jpg"
					display="block"
					width="100%"
					border-radius="25px"
					object-fit="cover"
				/>
				<Image
					src="https://oionslace.com/images/6.jpg"
					display="block"
					width="100%"
					border-radius="25px"
					object-fit="cover"
					margin="0px 0px 80px 0px"
					sm-margin="0px 0px 0 0px"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});